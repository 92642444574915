<template>
  <div class="sticky top-0 z-50">
    <div class="bg-gradient-to-r from-violet-500 to-fuchsia-500 pb-1">
      <div class="navbar bg-neutral text-neutral-content">
        <div class="max-w-6xl w-full m-auto">
          <img class="h-6 w-auto ml-3" src="/img/logo-sm.png" alt=""/>
          <BackButton classNames="btn btn-ghost lg:text-xl ml-3"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue"

export default {
  name: 'PortfolioNavbar',
  components: {BackButton}
}
</script>

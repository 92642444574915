<template>
  <div class="sticky top-0 z-50">
    <div class="bg-gradient-to-r from-violet-500 to-fuchsia-500 pb-1">

        <div class="navbar bg-neutral text-neutral-content">
          <div class="max-w-6xl w-full m-auto">
            <img class="h-6 w-auto ml-3" src="/img/logo-sm.png" alt="" />

            <span class="hidden md:block">
              <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('top')">
                Home
              </button>
              <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('about')">
                About
              </button>
              <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('portfolio')">
                Portfolio
              </button>
              <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('projects')">
                Projects
              </button>
              <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('contact')">
                Contact
              </button>
            </span>

            <div class="flex-none sm:block md:hidden ml-2">
              <Menu>
                <MenuButton>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      class="inline-block h-6 w-6 stroke-current">
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 6h16M4 12h16M4 18h16"></path>
                  </svg>
                </MenuButton>
                <MenuItems class="absolute flex flex-col rounded-md bg-neutral">
                  <MenuItem>
                    <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('top')">
                      Home
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('about')">
                      About
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('portfolio')">
                      Portfolio
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('projects')">
                      Projects
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button class="btn btn-ghost lg:text-xl mx-2" v-on:click="scrollToSection('contact')">
                      Contact
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { scrollToSection } from '@/utils/utils';

export default {
  data() {
    return {
      scrollToSection,
    }
  },
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  }
};
</script>

<template>
  <div class="flex justify-center">
    <div class="mockup-browser bg-base-300 border drop-shadow-xl mb-14">
      <div class="mockup-browser-toolbar">
        <div class="input">{{url}}</div>
      </div>

      <div class="overflow-hidden min-w-full">
        <img class="w-full" :src="image" alt="Viking Cruises"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BrowserArtboard',
  props: {
    url: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
  },
}
</script>
<template>
  <section id="about" class="relative py-24 bg-white bg-repeat" style="background-image: url('/img/logo-s.png')">
    <h2 class="text-balance text-5xl font-semibold tracking-tight text-gray-700 text-center max-w-5xl mx-auto mb-14">
      About Phil
    </h2>

    <div class="lg:flex justify-center gap-20 max-w-6xl mx-auto">
      <div class="p-2 mx-14 mb-14 lg:mx-0 border-dashed border-4 border-gray-300 rounded-3xl">
        <div class="bg-gradient-to-tr from-fuchsia-500 to-orange-500 rounded-3xl p-4 border-none">

          <div class="flex justify-center">
            <div class="avatar">
              <div class="max-w-64 mb-10 rounded-full">
                <img src="/img/photo.png" alt="Phil Sanders Image" class="m-10"/>
              </div>
            </div>
          </div>

          <div class="sm:mb-8 sm:flex sm:justify-center pb-7">
            <div
                :class="[
                'relative',
                'rounded-full',
                'px-3',
                'py-1',
                'bg-white',
                'text-sm/6',
                'text-gray-700',
                'ring-1',
                'ring-gray-700/10',
                'hover:ring-gray-700/20'
              ]"
                v-on:click.prevent="getResume"
            >
              <a href="#" class="font-semibold text-indigo-600">
                <span class="absolute inset-0 btn btn-link" aria-hidden="true" />
                  Download PDF Resume<span aria-hidden="true">&rarr;
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="px-10 lg:px-0">
        <p class="text-center lg:text-left text-xl text-gray-700 max-w-3xl mx-auto mb-8">
          In my recent role as a Senior Engineer at Sourcetoad LLC, I contributed to the development of several high-profile
          projects, notably including an Android Native SDK for Certegy Payment Solutions (now BankPay) and Google
          Marketplace integrations for Viking Cruises. Working closely with cross-functional teams, I delivered
          high-quality, scalable solutions under tight deadlines. My experience spans web and mobile platforms,
          with a strong command of modern frameworks and languages such as React, React Native, Vue.js, Laravel,
          PHP, Swift, and Kotlin. I am also well-versed in web accessibility standards (WCAG), ensuring accessible
          and user-friendly designs across projects.
        </p>

        <p class="text-center lg:text-left text-xl text-gray-700 max-w-3xl mx-auto mb-8">
          I’m not a walking encyclopedia of code, nor do I hold formal degrees or certifications. What I do have is
          a relentless drive to learn and stay current with new and emerging technologies. Over the past year (2024),
          I immersed myself in Unreal Engine and its Blueprints system for game development, creating a
          side-scrolling space shooter that I plan to release in the near future.
        </p>
      </div>
    </div>

    <TimelineComponent />
  </section>
</template>

<script>
import TimelineComponent from "@/components/TimelineComponent.vue";

export default {
  components: {
    TimelineComponent,
  },
  methods: {
    getResume() {
      const url = '/resume/Phil Sanders Resume.pdf';
      window.open(url, '_blank')
    }
  }
}
</script>

<template>
  <h2
      class="max-w-6xl mx-auto px-8 text-balance font-semibold tracking-tight text-gray-700 mb-14"
      :class=[fontSize]
  >
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: 'PortfolioHeading',
  props: {
    fontSize: {
        type: String,
        default: "text-5xl"
      }
    }
}
</script>

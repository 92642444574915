<template>
  <hero-component />
  <NavMenu />
  <AboutMe />
  <MyPortfolio />
  <GameView />
  <ContactMe />
  <FooterBase />
</template>

<script>
  import '../assets/tailwind.css';

  import NavMenu from "@/components/NavMenu.vue";
  import AboutMe from "@/views/HomeAbout.vue";
  import MyPortfolio from "@/views/HomePortfolio.vue";
  import ContactMe from "@/views/HomeContact.vue";
  import FooterBase from "@/components/FooterBase.vue";
  import HeroComponent from "@/components/HeroComponent.vue";
  import GameView from "@/views/HomePersonalProjects.vue";

  export default {
    components: {
      GameView,
      HeroComponent,
      NavMenu,
      FooterBase,
      MyPortfolio,
      AboutMe,
      ContactMe
    },
  };
</script>

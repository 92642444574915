<template>
  <ProjectContainer page-title="Just Another Star Fighter">
    <template v-slot:hero-content>
      <div class="bg-neutral py-8">
        <h2  class="max-w-6xl mx-auto px-8 text-center text-balance font-semibold tracking-tight text-gray-700 mb-14 text-5xl">
          Funkin Family Music
        </h2>
      </div>
    </template>
    <template v-slot:info-content>
      <h3 class="max-w-3xl mx-auto text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        Funkin Family Music
      </h3>

      <p class="text-xl text-gray-700 max-w-3xl mx-auto mb-14">
        coming soon...
      </p>

      <h3 class="max-w-3xl mx-auto text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        PillFORM
      </h3>

      <p class="text-xl text-gray-700 max-w-3xl mx-auto mb-14">
        coming soon...
      </p>
    </template>
    <template v-slot:level-up-content>
      <ul class="list-image-starG list-inside">
        <li class="leading-10">Music</li>
      </ul>
    </template>
  </ProjectContainer>
</template>

<script>
  import ProjectContainer from "@/components/ProjectContainer.vue";

  export default {
    components: {
      ProjectContainer
    }
  }
</script>

<template>
  <section id="projects" class="relative py-24 bg-neutral overflow-hidden">
    <svg
        aria-hidden="true"
        class="absolute h-full w-full -mt-24"
        style="mask-image: radial-gradient(100% 100% at top right,white,transparent); stroke: rgb(255 255 255 / 0.1);"
    >
      <defs>
        <pattern x="50%" y="-1" id="squares-pattern" width="200" height="200" patternUnits="userSpaceOnUse">
          <path d="M.5 200V.5H200" fill="none"></path>
        </pattern>
      </defs>
      <rect fill="url(#squares-pattern)" width="100%" height="100%" stroke-width="0"></rect>
    </svg>

    <div class="relative max-w-6xl w-auto m-auto mb-14">
      <div aria-hidden="true" class="absolute inset-x-0 z-0 transform-gpu overflow-hidden blur-3xl">
        <div
            style="clip-path: ellipse(49% 31% at 50% 50%);"
            class="relative xl:left-[calc(50%-30rem)] top-16 aspect-[1155/678] md:w-auto xl:max-w-6xl bg-gradient-to-tr from-fuchsia-500 to-orange-500 opacity-30">
        </div>
      </div>

      <div class="relative">
        <div class="flex justify-center mb-8">
          <span class="sr-only">Phil Sanders</span>
          <img class="lgo max-w-20" src="/img/logo-sm.png" alt="Phil Sanders logo" />
        </div>

        <h2 class="text-balance text-5xl font-semibold tracking-tight text-gray-100 text-center mb-6">Personal Projects</h2>

        <div class="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-12 xl:mx-0">
          <div v-for="project in projects" :key="project.id" class="text-center">
            <img :src="project.image" alt="Just Another Star Fighter Image" class="rounded-lg shadow-lg mb-4 m-auto">
            <h3 class="text-xl font-semibold">{{ project.name }}</h3>
            <p class="text-gray-400">{{ project.description }}</p>
            <button
                class="btn btn-sm btn-outline btn-secondary mt-6 mb-14 lg:mb-0"
                v-on:click="gotoPortfolio(project.url)"
            >
              Read More
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        projects: [
          {
            id: 1,
            name: 'Just Another Star Fighter',
            description: 'Phaser JS Game',
            image: '/img/thumbs-star-fighter-game.png',
            url: '/project/just-another-star-fighter'
          },
          {
            id: 2,
            name: 'Solar Winds',
            description: 'Unreal Engine Game',
            image: '/img/thumbs-solar-winds-game.png',
            url: '/project/solar-winds'
          },
          {
            id: 3,
            name: 'Funkin Family Music',
            description: 'Electronic Music Production',
            image: '/img/thumbs-funkin-family-music.png',
            url: '/project/music'
          }
        ],
      };
    },
    methods: {
      gotoPortfolio(url) {
        this.$router.push(url)
      }
    }
  };
</script>

<style scoped>
  .modal-action {
    display: block;
  }
  .ct {
    padding-inline-end: 1rem;
  }
</style>
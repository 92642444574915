<template>
  <div class="relative">
    <div id="LoadingSpinner" class="absolute top-[calc(50%-30px)] left-[calc(50vw-20px)]">
      <span class="loading loading-spinner loading-lg"></span>
    </div>
    <div ref="gameContainer" id="GameContainer" class="game-container"></div>
  </div>
</template>

<script>
import Phaser from 'phaser';

class StartScene extends Phaser.Scene {
  constructor() {
    super({ key: 'StartScene' });
    this.loaded = false;
  }

  preload() {
    this.load.on('complete', () => {
      document.getElementById('GameContainer').style.opacity = '0';

      this.time.delayedCall(2000, () => {
        document.getElementById('LoadingSpinner').remove()
        document.getElementById('GameContainer').style.opacity = '1';
        this.loaded = true;
      })
    });

    this.load.image('player', '/img/game/player-image.png');
  }

  create() {
    this.playerIcon = this.physics.add.sprite(200, 100, 'player');

    this.add.text(200, 200, 'Just Another', {
      fontSize: '32px',
      fill: '#ffffff'
    }).setOrigin(0.5);

    this.add.text(200, 240, 'Star Fighter', {
      fontSize: '48px',
      fill: '#ffffff'
    }).setOrigin(0.5);

    this.add.text(200, 350, 'Press SPACE to Start', {
      fontSize: '24px',
      fill: '#ffffff'
    }).setOrigin(0.5);

    this.input.keyboard.on('keydown-SPACE', () => {
      if (this.loaded) {
        this.scene.start('StarFighterScene');
      }
    });
  }
}

class GameScene extends Phaser.Scene {
  constructor() {
    super({ key: 'StarFighterScene' });
    this.gameover = false;
    this.player = null;
    this.health = 100;
    this.maxHealth = 100;
    this.healthPickups = null;
    this.healthBar = null;
    this.cursors = null;
    this.enemies = null;
    this.bullets = null;
    this.enemyBullets = null;
    this.lastFired = 0;
    this.lives = 3;
    this.livesText = null;
    this.score = 0;
    this.scoreText = null;
    this.isInvincible = false;
  }

  preload() {
    this.load.image('player', '/img/game/player-image.png');
    this.load.spritesheet('enemy', '/img/game/enemy-sprite.png', { frameWidth: 40, frameHeight: 40 });
    this.load.spritesheet('bullet', '/img/game/bullet-sprite.png', { frameWidth: 7, frameHeight: 29 });
    this.load.image("enemyBullet", '/img/game/enemy-bullet-image.png');
    this.load.spritesheet('enemyBullet', '/img/game/health-pickup-sprite.png', { frameWidth: 11, frameHeight: 10 });
    this.load.image("playerIcon", '/img/game/player-icon-image.png');
    this.load.spritesheet('healthPickup', '/img/game/health-pickup-sprite.png', { frameWidth: 20, frameHeight: 20 });
  }

  create() {
    this.player = this.physics.add.sprite(200, 570, 'player').setCollideWorldBounds(true);
    this.playerIcon = this.physics.add.sprite(10, 15, 'playerIcon');
    this.cursors = this.input.keyboard.createCursorKeys();
    this.input.keyboard.on('keydown-SPACE', this.fireBullet, this);

    this.bullets = this.physics.add.group({
      defaultKey: 'bullet',
      maxSize: 10
    });

    this.enemies = this.physics.add.group();
    this.time.addEvent({
      delay: 1000,
      callback: this.spawnEnemy,
      callbackScope: this,
      loop: true
    });

    this.enemyBullets = this.physics.add.group({
      defaultKey: 'enemyBullet',
      maxSize: 20
    });

    this.healthPickups = this.physics.add.group();

    this.physics.add.collider(this.bullets, this.enemies, this.destroyEnemy, null, this);
    this.physics.add.collider(this.player, this.enemies, this.hitPlayer, null, this);
    this.physics.add.collider(this.player, this.enemyBullets, this.hitPlayerWithEnemyBullet, null, this);
    this.physics.add.collider(this.player, this.healthPickups, this.collectHealthPickup, null, this);

    this.scoreText = this.add.text(290, 10, `Score: ${this.score}`, {
      fontSize: '16px',
      fill: '#fff'
    });

    this.livesText = this.add.text(32, 10, `${this.lives}`, {
      fontSize: '20px',
      fill: '#fff'
    });

    // Player Health bar setup
    this.healthBar = this.add.graphics();
    this.updateHealthBar();

    // Define the player bullet pulse animation
    this.anims.create({
      key: 'bulletPulse',
      frames: this.anims.generateFrameNumbers('bullet', { start: 0, end: 1 }),
      frameRate: 10,
      repeat: -1
    });

    // Define the health glow item animation
    this.anims.create({
      key: 'healthGlow',
      frames: this.anims.generateFrameNumbers('healthPickup', { start: 0, end: 2 }),
      frameRate: 10,
      repeat: -1
    });

    // Define the enemy fly animation
    this.anims.create({
      key: 'enemyFly',
      frames: this.anims.generateFrameNumbers('enemy', { start: 0, end: 3 }),
      frameRate: 10,
      repeat: -1
    });
  }

  update() {
    if (!this.gameover) {
      if (this.cursors.left.isDown) {
        this.player.setVelocityX(-200);
      } else if (this.cursors.right.isDown) {
        this.player.setVelocityX(200);
      } else {
        this.player.setVelocityX(0);
      }
    }

    Phaser.Actions.IncY(this.enemies.getChildren(), 1);

    this.bullets.children.iterate(bullet => {
      if (bullet && bullet.y < 0) {
        bullet.setActive(false);
        bullet.setVisible(false);
      }
    });

    this.enemies.children.iterate(enemy => {
      if (enemy.active && Phaser.Math.Between(0, 100) < 2 && !enemy.firing) {
        enemy.firing = true;
        this.fireEnemyBullet(enemy);
        this.time.delayedCall(250, () => {
          enemy.firing = false;
        });
      }
    });

    this.enemyBullets.children.iterate(bullet => {
      if (bullet && bullet.y > 600) {
        bullet.setActive(false);
        bullet.setVisible(false);
      }
    });
  }

  fireBullet() {
    const timeNow = this.time.now;
    if (timeNow - this.lastFired < 300) return;
    this.lastFired = timeNow;

    const bullet = this.bullets.get(this.player.x, this.player.y - 20);
    if (bullet && !this.gameover) {
      bullet.setActive(true);
      bullet.setVisible(true);
      bullet.body.velocity.y = -600;
      bullet.play('bulletPulse');
    }
  }

  fireEnemyBullet(enemy) {
    const bullet = this.enemyBullets.get(enemy.x, enemy.y + 20);
    if (bullet && !this.gameover) {
      bullet.setActive(true);
      bullet.setVisible(true);
      bullet.body.velocity.y = 400;
      bullet.damagePoints = 5;
    }
  }

  spawnEnemy() {
    if (!this.gameover) {
      const x = Phaser.Math.Between(20, 350);
      const enemy = this.enemies.create(x, 60, 'enemy');
      enemy.points = 25;
      enemy.damagePoints = 24;
      enemy.setVelocityY(120);
      enemy.play('enemyFly');
    }
  }

  destroyEnemy(bullet, enemy) {
    bullet.destroy();
    enemy.destroy();

    this.showEnemyFloatingPointValue(enemy);

    this.score += enemy.points;
    this.scoreText.setText(`Score: ${this.score}`);

    if (this.health < 100 && Phaser.Math.Between(0, 100) < 40) { // 40% chance. TODO lessen over time
      const healthPickup = this.healthPickups.create(enemy.x, enemy.y, "healthPickup");
      healthPickup.setVelocityY(200);
      healthPickup.play('healthGlow');
    }
  }

  showEnemyFloatingPointValue(enemy) {
    const pointValue = enemy.points;

    const floatingText = this.add.text(enemy.x, enemy.y, `+${pointValue}`, {
      font: '16px Arial',
      fill: '#fff',
      stroke: '#000000',
      strokeThickness: 3,
    });

    this.tweens.add({
      targets: floatingText,
      y: floatingText.y - 50,
      alpha: 0,
      duration: 2000,
      ease: 'Power1',
      onComplete: () => {
        floatingText.destroy();
      },
    });
  }

  hitPlayer(player, enemy) {
    enemy.destroy();
    const damage = enemy.damagePoints;

    if (!this.isInvincible) {
      this.health -= damage;
      this.updateHealthBar();
      this.showPlayerFloatingDamage(damage);

      if (this.health <= 0) {
        this.lives -= 1;
        this.health = 100;
        this.updateHealthBar();
        this.livesText.setText(`${this.lives}`);
      }

      this.isInvincible = true;
      this.time.delayedCall(100, () => {
        this.isInvincible = false
      });
    }

    if (this.lives <= 0) {
      this.gameOver();
    }
  }

  hitPlayerWithEnemyBullet(player, bullet) {
    bullet.destroy();
    const damage = bullet.damagePoints;

    if (!this.isInvincible) {
      this.health -= damage;
      this.updateHealthBar();
      this.showPlayerFloatingDamage(damage);

      if (this.health <= 0) {
        this.lives -= 1;
        this.health = 100;
        this.updateHealthBar();
        this.livesText.setText(`${this.lives}`);

        this.isInvincible = true;
        this.time.delayedCall(100, () => {
          this.isInvincible = false;
        });
      }
    }

    if (this.lives <= 0) {
      this.gameOver();
    }
  }

  showPlayerFloatingDamage(damage) {
    const damageText = this.add.text(this.player.x, this.player.y - 40, `-${damage}`, {
      font: '16px Arial',
      fill: '#f6872d',
      fontWeight: 'bold',
    }).setOrigin(0.5);

    this.tweens.add({
      targets: damageText,
      x: this.player.x - 50,
      alpha: 0,
      duration: 1000,
      ease: 'Power1',
      onComplete: () => {
        damageText.destroy();
      }
    });
  }

  collectHealthPickup(player, healthPickup) {
    const healthValue = 20;
    this.showFloatingHealthPickupValue(healthPickup, healthValue);
    this.health = Math.min(this.health + healthValue, this.maxHealth);
    healthPickup.destroy();
    this.updateHealthBar();
  }

  showFloatingHealthPickupValue(healthPickup, healthValue) {
    const floatingText = this.add.text(healthPickup.x, healthPickup.y - 20, `+${healthValue}`, {
      font: '16px Arial',
      fill: '#a3c15c',
      ontWeight: 'bold',
    });

    this.tweens.add({
      targets: floatingText,
      x: floatingText.x + 50,
      alpha: 0,
      duration: 2000,
      ease: 'Power1',
      onComplete: () => {
        floatingText.destroy();
      },
    });
  }

  updateHealthBar() {
    this.healthBar.clear();

    this.healthBar.fillStyle(0xf6872d);
    this.healthBar.fillRect(60, 12, this.health * 2, 10);

    const targetWidth = this.health * 2;

    this.tweens.add({
      targets: this.healthBar,
      displayWidth: targetWidth,
      duration: 500,
      onUpdate: () => {
        this.healthBar.clear();
        this.healthBar.fillStyle(0xa3c15c);
        this.healthBar.fillRect(60, 12, targetWidth, 10);
      }
    });
  }

  gameOver() {
    this.gameover = true;
    this.health = 0;
    this.player.destroy();
    this.updateHealthBar();
    this.physics.pause();
    this.add.text(200, 300, 'Game Over', {
      fontSize: '40px',
      fill: '#f6872d'
    }).setOrigin(0.5);

    this.enemyBullets.children.iterate(bullet => {
      if (bullet) {
        bullet.setVisible(false);
      }
    });

    this.time.delayedCall(4000, () => {
      this.lives = 3;
      this.health = 100;
      this.score = 0;
      this.isInvincible = false;
      this.gameover = false;
      this.player = this.physics.add.sprite(200, 570, 'player').setCollideWorldBounds(true);
      this.scene.start('StartScene');
    });
  }
}

export default {
  name: 'StarFighterGame',
  data() {
    return {
      phaserGame: null
    };
  },
  mounted() {
    this.phaserGame = new Phaser.Game({
      type: Phaser.AUTO,
      width: 400,
      height: 600,
      transparent: true,
      backgroundColor: '#1e293b',
      scene: [StartScene, GameScene],
      physics: {
        default: 'arcade',
        arcade: { debug: false }
      },
      parent: this.$refs.gameContainer,
      scale: {
        mode: Phaser.Scale.FIT,
        parent: this.$refs.gameContainer,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: 400,
        height: 600
      },
    });
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    if (this.phaserGame) {
      this.phaserGame.destroy(true);
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === ' ' && !event.target.isContentEditable) {
        event.preventDefault();
      }
    }
  }
};
</script>

<style scoped>
  .game-container {
    width: 400px;
    height: 600px;
    margin: 0 auto;
  }
</style>

<style>
@media screen and (max-width: 568px) {
  .game-container {
    transform: scale(.8,.8);
  }
  .game-container > canvas {
    margin: 0;
  }
}
@media screen and (max-width: 384px) {
  .game-container {
    transform: scale(.65,.65);
  }

  .game-container > canvas {
    margin: 0 !important;
  }
}
</style>

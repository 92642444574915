<template>
  <ProjectContainer page-title="Just Another Star Fighter">
    <template v-slot:hero-content>
      <div class="bg-neutral py-8">
        <StarFighterGame />
      </div>
      <div class="collapse p-4 pt-0 border border-b-0 border-l-0 border-r-0 border-t-gray-500 rounded-none bg-neutral">
        <input ref="controls" type="checkbox" />
        <h3 class="collapse-title ct text-balance font-semibold tracking-tight text-center mt-2 pb-0 @lg:underline">
          Controls
        </h3>
        <div class="collapse-content flex w-full justify-center gap-6 align-middle">
          <p>Fire</p>
          <kbd class="kbd"> space </kbd>
          <p>Move</p>
          <kbd class="kbd">◀</kbd>
          <kbd class="kbd">▶</kbd>
        </div>
      </div>
    </template>
    <template v-slot:info-content>
      <h3 class="max-w-3xl mx-auto text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        Phase JS Game
      </h3>

      <p class="text-xl text-gray-700 max-w-3xl mx-auto mb-14">
        coming soon...
      </p>
    </template>
    <template v-slot:level-up-content>
      <ul class="list-image-starG list-inside">
        <li class="leading-10">Phaser JS</li>
      </ul>
    </template>
  </ProjectContainer>
</template>

<script>
  import ProjectContainer from "@/components/ProjectContainer.vue";
  import StarFighterGame from "@/components/StarFighterGame.vue";

  export default {
    components: {
      StarFighterGame,
      ProjectContainer
    }
  }
</script>

<template>
  <div>
    <h3 class="text-balance text-3xl font-semibold tracking-tight text-gray-700 text-center mt-12 mb-6">
      Development Timeline
    </h3>
  </div>
  <div class="overflow-x-scroll scrollable">
  <div class="flex justify-center pl-8">
    <ul class="timeline">
      <li>
        <div class="timeline-start text-black">1979</div>
        <div class="timeline-middle">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="text-violet-500 h-5 w-5">
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd" />
          </svg>
        </div>
        <div class="timeline-end timeline-box">The movie Alien and Phil where born</div>
        <hr/>
      </li>
      <li>
        <hr />
        <div class="timeline-start text-black">1996</div>
        <div class="timeline-middle">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="text-violet-500 h-5 w-5">
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd" />
          </svg>
        </div>
        <div class="timeline-end timeline-box">Writes First Html</div>
        <hr />
      </li>
      <li>
        <hr />
        <div class="timeline-start text-black">2000</div>
        <div class="timeline-middle">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="text-violet-500 h-5 w-5">
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd" />
          </svg>
        </div>
        <div class="timeline-end timeline-box">First Flash Website</div>
        <hr />
      </li>
      <li>
        <hr />
        <div class="timeline-start text-black">2002</div>
        <div class="timeline-middle">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="text-violet-500 h-5 w-5">
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd" />
          </svg>
        </div>
        <div class="timeline-end timeline-box">First Dev Job</div>
        <hr />
      </li>
      <li>
        <hr />
        <div class="timeline-start text-black">2014</div>
        <div class="timeline-middle">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="text-violet-500 h-5 w-5">
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd" />
          </svg>
        </div>
        <div class="timeline-end timeline-box">Enter Sourcetoad</div>
        <hr />
      </li>
      <li class="pr-20">
        <hr />
        <div class="timeline-start text-black">Present</div>
        <div class="timeline-middle">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="text-orange-500 h-5 w-5">
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clip-rule="evenodd" />
          </svg>
        </div>
        <div class="timeline-end timeline-box">Pretty Decent Developer</div>
      </li>
    </ul>
  </div>
  </div>
</template>

<style scoped>
  .scrollable::-webkit-scrollbar {
    display: none;
  }
  .scrollable {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
</style>

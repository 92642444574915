<template>
  <portfolio-page
      art-board-image="/img/cover-05.png"
      art-board-url="https://developer.certegy.com"
      page-title="Thompson Consulting"
  >
    <template v-slot:info-content>
      <h3 class="max-w-3xl mx-auto text-balance text-3xl font-semibold tracking-tight text-gray-700 mb-14">
        Mobile App
      </h3>

      <p class="text-xl text-gray-700 max-w-3xl mx-auto mb-14">
        coming soon...
      </p>
    </template>
    <template v-slot:level-up-content>
      <ul class="list-image-starG list-inside">
        <li class="leading-10">React-Native</li>
      </ul>
      <ul class="list-image-star list-inside">
        <li class="leading-10">Redux</li>
        <li class="leading-10">Redux Sagas</li>
        <li class="leading-10">ZPL Language</li>
      </ul>
      <ul class="list-image-starH list-inside">
        <li class="leading-10">Java</li>
        <li class="leading-10">Swift</li>
      </ul>
    </template>
  </portfolio-page>
</template>

<script>
import PortfolioPage from "@/components/PortfolioContainer.vue";

export default {
  components: {
    PortfolioPage,
  }
}
</script>

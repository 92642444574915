<template>
  <PortfolioNavbar />
  <section class="pb-24 bg-purple-100">
    <slot name="hero-content" />
    <div class="flex flex-col lg:flex-row w-full justify-between px-8 gap-16 max-w-6xl mt-16 mx-auto">
      <div class="max-w-3xl">
        <slot name="info-content" />
      </div>
      <div class="lg:max-w-96 flex-grow flex-shrink-0">
        <div class="bg-neutral rounded-xl shadow-lg p-8 w-full">
          <h3 class="text-2xl text-balance font-bold tracking-tight text-gray-300 mb-4">Leveled Up</h3>
          <slot name="level-up-content" />
        </div>
      </div>
    </div>
    <BackButton class="mt-14"/>
  </section>
  <FooterBase class-names="-mt-2"/>
</template>

<script>
import PortfolioNavbar from "@/components/PortfolioNavbar.vue";
import BackButton from "@/components/BackButton.vue";
import FooterBase from "@/components/FooterBase.vue";

export default {
  name: 'ProjectContainer',
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
  },
  components: {
    FooterBase,
    BackButton,
    PortfolioNavbar
  }
}
</script>

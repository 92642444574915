<template>
  <section id="contact" class="bg-white py-24 p-6 bg-repeat" style="background-image: url('/img/logo-s.png')">
    <h2 class="text-balance text-5xl font-semibold tracking-tight text-gray-700 text-center mb-6">Contact Phil</h2>

    <p class="text-center text-xl text-gray-700 max-w-3xl mx-auto pb-8">Let's chat</p>

    <div class="p-2 mx-8 mb-14 border-dashed border-4 border-gray-300 rounded-3xl lg:max-w-2xl lg:m-auto">
      <form @submit.prevent="sendEmail" class="max-w-lg mx-auto">
        <div class="mb-4">
          <label class="block text-gray-700">Name</label>
          <input type="text" v-model="form.name" id="name" class="w-full border border-gray-300 p-2 rounded-lg">
        </div>
        <div class="mb-4">
          <label class="block text-gray-700">Email</label>
          <input type="email" v-model="form.email" id="email" class="w-full border border-gray-300 p-2 rounded-lg">
        </div>
        <div class="mb-4">
          <label class="block text-gray-700">Message</label>
          <textarea
              v-model="form.message"
              id="message"
              class="w-full border border-gray-300 p-2 rounded-lg h-40">
          </textarea>
        </div>
        <div class="flex justify-center">
          <button
            type="submit"
            :class="[
              'btn',
              'bg-gradient-to-r',
              'from-violet-500',
              'to-fuchsia-500',
              'border-none',
              'text-white',
              'hover:text-neutral-900',
              'mb-8'
            ]"
          >
            Send Message
          </button>
        </div>

        <div
            v-if="responseMessage"
            role="alert"
            class="mt-8 alert alert-success"
            :class="{
              'alert-success': isSuccess,
              'alert-error': !isSuccess
            }"
          >
          <svg
              v-if="isSuccess"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 shrink-0 stroke-current"
              fill="none"
              viewBox="0 0 24 24">
            <path
                class="text-gray-100"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 shrink-0 stroke-current"
              fill="none"
              viewBox="0 0 24 24">
            <path
                class="text-gray-100"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span class="text-gray-100">{{ responseMessage }}</span>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      responseMessage: '',
      isSuccess: false,
    };
  },
  methods: {
    async sendEmail() {
      this.isSuccess = false;
      this.responseMessage = '';

      try {
        const response = await fetch('/app.php/contact', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify(this.form),
        });

        if (response.ok) {
          const result = await response.json();
          this.responseMessage = result.message || 'Email sent successfully!';
          this.isSuccess = true;
          this.form = {
            name: '',
            email: '',
            message: ''
          }
        } else {
          const error = await response.json();
          this.responseMessage = error.message || 'Failed to send email.';
        }
      } catch (err) {
        this.responseMessage = 'An error occurred. Please try again.';
      }
    },
  }
};
</script>

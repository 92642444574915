<template>
  <section id="top" class="relative overflow-hidden bg-gradient-to-tr from-gray-950 to-sky-950">
    <svg
        aria-hidden="true"
        class="absolute h-full w-full"
        style="mask-image: radial-gradient(100% 100% at top right,white,transparent); stroke: rgb(255 255 255 / 0.1);"
    >
      <defs>
        <pattern x="50%" y="-1" id="squares-pattern" width="200" height="200" patternUnits="userSpaceOnUse">
          <path d="M.5 200V.5H200" fill="none"></path>
        </pattern>
      </defs>
      <rect fill="url(#squares-pattern)" width="100%" height="100%" stroke-width="0"></rect>
    </svg>

    <div class="relative isolate max-h-[45rem] 2xl:max-w-[80vw] m-auto pt-[5rem] pb-[2rem] px-6 lg:px-8">
      <div class="flex justify-center md:justify-start">

        <div class="md:min-w-[30rem] px-4 md:px-8">
          <div class="flex justify-center mt-0 md:mt-16">
            <span class="sr-only">Phil Sanders</span>
            <img class="max-w-[5rem] mb-[2rem]" src="/img/logo-sm.png" alt="Phil Sanders logo" />
          </div>

          <div class="text-center">
            <h1 class="text-balance font-semibold tracking-tight text-gray-50 text-5xl xl:text-7xl">
              Phil Sanders, Web Developer
            </h1>

            <p class="mt-8 text-pretty text-lg font-medium text-gray-50 sm:text-xl/8">
              UX Engineer, Graphic Designer, Unreal Engine Game Development Enthusiast, Musician
            </p>
            <div class="flex items-center justify-center gap-x-6 mt-10">
              <button
                  :class="[
                    'btn',
                    'bg-gradient-to-r',
                    'from-violet-500',
                    'to-fuchsia-500',
                    'border-none',
                    'text-white',
                    'shadow-lg',
                    'shadow-indigo-500/50',
                    'hover:text-neutral-900',
                    'mb-16 md:mb-0'
                  ]"
                  v-on:click="scrollToSection('about')"
              >
                Get To Know Me
              </button>
            </div>
          </div>
        </div>

        <div class="hidden md:block flex-none w-auto">
          <div id="carousel" class="carousel rounded-box w-[76rem]">
            <div
                class="carousel-item w-full"
                v-for="slide in carouselSlides"
                :key="slide.id"
                :id="'slide' + slide.id"
            >
              <img
                class="w-full"
                :src="slide.url"
                :alt="slide.alt"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { scrollToSection } from '@/utils/utils';

export default {
  data() {
    return {
      scrollToSection,
      carouselSlides: [
        {
          id: 0,
          url: '/img/cover-02.png',
          alt: 'App screenshot',
        },
        {
          id: 1,
          url: '/img/cover-01.png',
          alt: 'App screenshot',
        },
        {
          id: 2,
          url: '/img/cover-03.png',
          alt: 'App screenshot',
        },
        {
          id: 3,
          url: '/img/cover-industry-genome.png',
          alt: 'Industry Genome',
        },
        {
          id: 3,
          url: '/img/cover-05.png',
          alt: 'App screenshot',
        },
      ],
      carouselTimeout: undefined
    }
  },
  mounted() {
    this.initializeCarousel();
  },
  unmounted() {
    clearInterval(this.carouselTimeout);
  },
  methods: {
    initializeCarousel() {
      let slideIndex = 0;
      const carousel = document.getElementById('carousel');
      carousel.scrollLeft = 0;

      this.carouselTimeout = setInterval(() => {
        const element = document.getElementById('slide' + slideIndex);

        if (slideIndex + 1 < this.carouselSlides.length) {
          carousel.scrollLeft = element.offsetLeft;
        }
        else {
          carousel.scrollLeft = 0;
        }

        slideIndex = slideIndex + 1 < this.carouselSlides.length ? slideIndex + 1 : 0;
      }, 3000)
    }
  }
};
</script>
